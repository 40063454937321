.quote-banner {
    --background-overlay: '';
    background-color: transparent;
    background-image: linear-gradient(180deg, #E5E5E5 0%, #CCFBC4 100%);
    transition: background var(--overlay-transition, .3s), border-radius var(--border-transition, .3s), opacity var(--overlay-transition, .3s);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .quote-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    color: #0591cc;
    font-weight: bold;
    margin-bottom: 0.5rem; /* Add this line to reduce the margin between quote-text and quote-author */
  }
  
  .quote-author {
    font-style: italic;
    font-size: 2rem;
    color: #0591cc;
    font-weight: bold;
    margin-top: 0.5rem; /* Add this line to reduce the margin between quote-text and quote-author */
  }

  @media (max-width: 768px) {
    .quote-text {
      font-size: 1.5rem;
    }
    
    .quote-author {
      font-size: 1.5rem;
    }
  }