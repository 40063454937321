/* .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
  } */
  
  .content {
    max-width: 1200px;
    width: 100%;
  }
  
  .main-title {
    color: #0088cc;
    font-family: 'DM Sans', sans-serif;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .intro-text {
    font-family: 'DM Sans', sans-serif;
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1.6;
    font-size: 1.1rem;
  }
  
  .section-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  
  .feature-list {
    font-family: 'DM Sans', sans-serif;
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 2rem;
  }
  
  .feature-list li {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  .featured-content {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .feature-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .feature-image {
    width: 100%;
    /* height: 200px; */
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 1rem;
  }
  
  .feature-item h3 {
    color: #0088cc;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
  
  .feature-item p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .featured-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .feature-image {
      width: 90%;
      max-width: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .feature-image {
      width: 95%;
      max-width: 300px;
    }
  }