.about-us-section {
  display: flex;
  align-items: flex-start;
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-height: 70vh;
  overflow: hidden;
}

.about-us-content {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  justify-content: center;
  align-items: center;
}

.about-us-centered-container {
  max-width: 80%;
}

.about-us-subtitle {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #888;
  text-transform: uppercase;
}

.about-us-title {
  font-family: 'DM Sans', sans-serif;
  font-size: 2rem;
  color: #0591cc;
  margin-bottom: 1.5rem;
  /* line-height: 1.3; */
}

.about-us-description {
  font-size: 1.8rem;
  color: #4caf50;
  /* line-height: 1.3; */
  margin-bottom: 2rem;
}

.find-out-more-btn.p-button.p-button-outlined {
  color: #0591cc;
  border-color: #000000;
  background-color: transparent;
  margin: 0 auto;
  display: block;
}

.find-out-more-btn.p-button.p-button-outlined:hover {
  background-color: #0591cc;
  color: #ffffff;
  border-color: #000000;
}

.about-us-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 40%;
  overflow: hidden;
}

.about-us-image img {
  max-height: 50vh;
  border-radius: 8px;
  width: auto;
  height: 100%;
}

@media (max-width: 1160px) {
  .about-us-section {
    max-height: unset;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
  }

  .about-us-content {
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .about-us-centered-container {
    max-width: 100%;
    
  }

  .about-us-title {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  .about-us-description {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .about-us-button {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .find-out-more-btn.p-button.p-button-outlined {
    margin: 0;
  }

  .about-us-image {
    justify-content: center;
    max-width: 100%;
    align-items: center;
  }

  .about-us-image img {
    max-height: 40vh;
  }

  /* Add new rules for columns */
  .about-us-content,
  .about-us-image {
    order: 2;
    margin-top: 2rem;
  }
}