.navbar-container {
    width: 100%;
    display: flex;
    justify-content: right;
    background: transparent;
    padding-top: 20px; /* Add top padding */
}

.navbar-content {
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
}
.custom-menubar.p-menubar .p-menuitem-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-menubar {
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
}

.p-menuitem {
    background-color: transparent;
}

.p-menuitem :hover {
    background-color: transparent;
}

.p-menuitem-text {
    color: white;
    border: none;
}

.p-menuitem-text:hover {
    border: none;
}
.p-menuitem:nth-child(5) {
    border: 1px solid white;
    border-radius: 5px;
    margin-left: 2rem;
    padding: 0.25rem 0.25rem;
}
/* ----------------------------------------------- */

@media screen and (max-width: 960px) {

    .p-menubar {
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
    }

    .p-menuitem {
        background-color: transparent;
    }

    .p-menuitem {
        background-color: transparent;
    }
    
    .p-menuitem :hover {
        background-color: transparent;
    }

    .p-menuitem-text {
        color: white;
        border: none;
    }
    .p-menubar-root-list {
        position: fixed; /* Changed from relative to fixed for better control */
        top: 80px; /* Adjust this value to move the menu lower */
        right: 0;
        margin-left: auto;
        margin-right: 0px;
        width: 150px;
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); 
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .15);
        backdrop-filter: blur(5px);
    }

    .p-menuitem:nth-child(5) {
        border: 1px solid white;
        border-radius: 5px;
        margin-left: 0.25rem;

    }

    .p-menubar-button .p-icon {
        color: white
    }

    .p-menubar-button:hover .p-icon{
        /* color: #5F748B; */
        color: white;
    }
    .p-menubar-button:hover{
        background-color: rgba(255, 255, 255, .15);
        backdrop-filter: blur(5px);
    }

    
}


