.beach-cta-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%; /* Adjust this value to match your image aspect ratio */
    overflow: hidden;
  }
  
  .beach-cta-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .beach-cta-overlay {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .beach-cta-text {
    color: black;
    text-align: center;
    max-width: 80%;
    font-size: 1.2rem;
    text-shadow: 0 0 5px white, 0 0 5px white, 0 0 5px white, 0 0 5px white;
  }
  
  /* Override PrimeReact button styles */
  .p-button.p-button-success {
    background-color: #22c55e;
    border-color: #22c55e;
  }
  
  .p-button.p-button-success:hover {
    background-color: #16a34a;
    border-color: #16a34a;
  }