.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links {
    display: flex;
    gap: 1rem;
    padding-left: 1.2rem;
}

.footer-links a {
    color: #5E696C;
    text-decoration: none;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.1rem;
}

.footer-title {
    padding-right: 1.2rem;
    font-family: 'DM Sans', sans-serif;
    color: #5E696C;
    font-size: 1rem;
}