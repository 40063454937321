.about-custom-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .about-custom-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/About-Us-cover.jpg');
    background-size: cover;
    background-position: 70%; /*Adjust to zoom and center on the interesting part of the image*/

    filter: brightness(0.9); /* Optional: slightly darken the image for better text contrast */
    z-index: -1;
    margin: 0 auto; /* Center the header horizontally */
    height: 40vh; /* Adjust the height as needed */
  }

  .about-content-container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .thanks {
    padding: 1.5rem;
  }

  .us{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .us-image {
    /* width: 100%; */
    height: 15rem;
    margin: 0 auto;
  }

  .text-regular {
    font-family: 'DM Sans', sans-serif;
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .text-italics {
    color: #000000;
    font-family: "Dancing Script", Sans-serif;
    font-weight: 100;
  }

  .text-regular-italics{
    color: #000000;
    font-family: "Dancing Script", Sans-serif;
    font-weight: 100;
    font-size: 1.5rem;
    text-align: center;

  }
  .text-regular-italics-name{
    color: #000000;
    font-family: "Dancing Script", Sans-serif;
    font-weight: 100;
    font-size: 3rem;
    margin-top: -1rem;
    margin-bottom: -.05rem;
    text-align: center;

  }
 
