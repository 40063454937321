  
  .header-container {
    position: relative;
    height: 400px;
  }
  
  .custom-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .custom-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/public/home-banner.jpg');
    background-size: cover;
    background-position: center 40%; /*Adjust to zoom and center on the interesting part of the image*/

    filter: brightness(0.8); /* Optional: slightly darken the image for better text contrast */
    z-index: -1;
    margin: 0 auto; /* Center the header horizontally */

  }
  
  .custom-header-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 800px;
    padding: 2rem;
    z-index: 1;
  }
  
  .custom-header-title {
    font-size: 350%;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  }
  
  .custom-header-title-emphasis {
    font-size: 5rem;
    display: block;
  }
  
  .custom-header-description {
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
  
  .content {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.6;
  } */
  
  /* Override PrimeReact button if using button */
  /* .p-button.p-button-success {
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
    background-color: #4CAF50; 
    border-color: #4CAF50;
  }
  
  .p-button.p-button-success:hover {
    background-color: #45a049;
    border-color: #45a049;
  } */
  
  /* Ensure Navbar is on top of the header */
  .header-container > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }